import React from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import primary from '@material-ui/core/colors/brown';
import secondary from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
    palette: {
        //contrastThreshold: 2,
        //type: 'dark',
        primary: {
            //main: primary[400],
            main: '#eba847',
        },
        secondary: {
            main: '#2f2e2e',
        },
    },
    typography: {
        fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    },
});

const Theme = ({ children }) => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
};

export default Theme;
