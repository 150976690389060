import React from 'react';
import ReactCompareImage from 'react-compare-image';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const items = {
    'building-completion': {
        id: 'building-completion',
        title: 'Virtual Building Completions',
        before: 'building-completion-1a.jpg',
        after: 'building-completion-1b.jpg',
    },
    declutter: {
        id: 'declutter',
        title: 'Virtual Declutter',
        before: 'declutter-1a.jpg',
        after: 'declutter-1b.jpg',
    },
    dessing: {
        id: 'dessing',
        title: 'Virtual Dressing',
        before: 'dressing-1a.jpg',
        after: 'dressing-1b.jpg',
    },
    'interior-design': {
        id: 'interior-design',
        title: 'Virtual Interior Design',
        before: 'interior-design-1a.jpg',
        after: 'interior-design-1b.jpg',
    },
    landscaping: {
        id: 'landscaping',
        title: 'Virtual Landscaping',
        before: 'landscaping-1a.jpg',
        after: 'landscaping-1b.jpg',
    },
    renovation: {
        id: 'renovation',
        title: 'Virtual Renovations',
        before: 'renovation-1a.jpg',
        after: 'renovation-1b.jpg',
    },
    staging: {
        id: 'staging',
        title: 'Virtual Staging',
        before: 'staging-1a.jpg',
        after: 'staging-1b.jpg',
    },
};

const BtnItem = ({ item, onClick, active }) => {
    const classes = useStyles();
    return (
        <div className={classnames(classes.btn, active && 'active')} onClick={onClick}>
            <div
                className={classes.img}
                style={{ backgroundImage: 'url(img/examples/' + item.after + ')' }}
            />
            <div className={classes.overlay} />
            <Typography className={classes.title} gutterBottom variant='h6' component='p'>
                {item.title}
            </Typography>
        </div>
    );
};

function ComingSoon() {
    const classes = useStyles();
    const [img, setImg] = React.useState('building-completion');

    const redirect = () => {
        window.location.href = 'https://www.propertystudios.co.uk/';
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={9} className={classes.left}>
                    <ReactCompareImage
                        leftImage={'img/examples/' + items[img].before}
                        rightImage={'img/examples/' + items[img].after}
                        sliderLineWidth={3}
                        handleSize={80}
                        sliderPositionPercentage={0.3}
                        sliderLineColor='#eba847'
                    />
                    <div className={classes.select}>
                        <Select value={img} onChange={(e) => setImg(e.target.value)}>
                            {Object.keys(items).map((key) => {
                                const item = items[key];
                                return (
                                    <MenuItem key={key} value={item.id}>
                                        {item.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </Grid>
                <Grid item md={3} className={classes.btns}>
                    <Scrollbars autoHide>
                        {Object.keys(items).map((key) => {
                            const item = items[key];
                            return (
                                <BtnItem
                                    key={item.id}
                                    item={item}
                                    onClick={() => setImg(item.id)}
                                    active={img === item.id}
                                />
                            );
                        })}
                    </Scrollbars>
                </Grid>
            </Grid>
            <div className={classes.logo} onClick={redirect} />
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    btns: {
        borderLeft: '3px solid #eba847',
        backgroundColor: '#2f2e2e',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    btn: {
        position: 'relative',
        borderBottom: '3px solid #eba847',
        '&:hover $overlay, &.active $overlay': {
            opacity: 0.5,
        },
        height: '14.28571428571429%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    img: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 80,
        height: 80,
        borderBottomRightRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '5%',
            left: '5%',
            width: '80%',
            height: '80%',
            backgroundImage: 'url(img/logo/logo-sm.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
        },
    },
    title: {
        color: '#fff',
        position: 'relative',
        padding: '2rem 0.5rem',
        cursor: 'pointer',
        margin: 0,
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(46,46,46,0.8) 0%, rgba(0,0,0,0.2) 100%)',
        transition: 'opacity 0.3s ease-in',
    },
    left: {
        position: 'relative',
        height: '100vh',
        backgroundColor: '#2f2e2e',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    select: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: 'rgba(255,255,255,0.6)',
        borderBottomLeftRadius: 15,
        borderBottom: '2px solid #eba847',
        borderLeft: '2px solid #eba847',
        paddingLeft: 5,
        overflow: 'hidden',
        '& .MuiInput-root': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '& .MuiInput-root:before, & .MuiInput-root:after': {
            borderColor: 'transparent !important',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

//#ac9259

export default ComingSoon;
